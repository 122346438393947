import React, { Fragment, Component } from 'react'
import _get from 'lodash/get'
import { Link, graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import { ChevronLeft, Mail, Facebook, Twitter, Linkedin, Instagram } from 'react-feather'
import { FacebookProvider, Comments, Page, Like } from 'react-facebook';

import Content from '../components/Content'
import Layout from '../components/Layout'
import './SinglePost.css'

export const SinglePostTemplate = ({
  title,
  date,
  tags,
  body,
  nextPostURL,
  prevPostURL,
  author,
  namaauthor,
  bioauthor,
  fotoauthor,
  facebookauthor,
  twitterauthor,
  instagramauthor,
  linkedinauthor,
  emailauthor,
  alamatutama = 'https://philo.shop',
  alamatpost,
  categories = []
}) => (
  <main>
    <article
      className="SinglePost section light"
      itemScope
      itemType="http://schema.org/BlogPosting"
    >
      <div className="container">
        <Link className="SinglePost--BackButton" to="/blog/" hreflang="en">
          <ChevronLeft /> BACK
        </Link>
        <div className="SinglePost--Content relative">
          <div className="SinglePost--InnerContent">
            <div className="SinglePost--Meta">
            {date && (
              <time
                className="SinglePost--Meta--Date"
                itemProp="dateCreated pubdate datePublished"
                date={date}
              >
                {date}
              </time>
            )}
            {categories && (
              <Fragment>
                <span>|</span>
                {categories.map((cat, index) => (
                  <span
                    key={cat.category}
                    className="SinglePost--Meta--Category"
                  >
                    {cat.category}
                    {/* Add a comma on all but last category */}
                    {index !== categories.length - 1 ? ',' : ''}
                  </span>
                ))}
              </Fragment>
            )}
          </div>
          
          {title && (
            <h1 className="SinglePost--Title" itemProp="title">
              {title}
            </h1>
          )}

            <Content source={body} />
            <br />
            <div>
              {tags && tags.length ? (
                <div style={{ marginTop: `4rem` }}>
                  <h4>Tags</h4>
                  <ul className="taglist">
                    {tags.map(tag => (
                      <li key={tag + `tag`}>
                        <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}

              <h2>Share This Post</h2>
              <ul className="sharer-social-links">
                <li><a href={'https://www.facebook.com/sharer/sharer.php?u=' + alamatutama + alamatpost} target="_blank" rel="noopener noreferrer"><Facebook className="facebook-icon" /></a></li>
                <li><a href={'https://www.twitter.com/share?url=' + alamatutama + alamatpost + '&text=' + title + '&viatwitterHandle'} target="_blank" rel="noopener noreferrer"><Twitter className="twitter-icon" /></a></li>
                <li><a href={'https://www.linkedin.com/shareArticle?url=' + alamatutama + alamatpost} target="_blank" rel="noopener noreferrer"><Linkedin className="linkedin-icon" /></a></li>
                <li><a href={'https://wa.me/?text=' + alamatutama + alamatpost} target="_blank" rel="noopener noreferrer"><div className="whatsapp-icon" /></a></li>
                <li><a href={'fb-messenger://share?link=' + alamatutama + alamatpost + '&app_id=363762830961156'} target="_blank" rel="noopener noreferrer"><div className="fbmessenger-icon" /></a></li>
              </ul>
            </div>
          </div>

          {author && (
            <div className="authorcard">
              <div>
                <h2 className="taCenter">Author</h2>
                <div>
                  {fotoauthor && (
                  <div className="fotoauthor"
                    style={{
                      background: `url(${fotoauthor})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "50% 50%",
                      marginBottom: 30
                    }}
                  />

                  )}
                  {namaauthor && (
                  <h3 className="taCenter">{namaauthor}</h3>
                  )}
                  {bioauthor && (
                  <p className="taCenter">{bioauthor}</p>
                  )}
                  <div className="taCenter">
                    <ul className="author-social-links">
                      {facebookauthor && (
                      <li><a href={`${facebookauthor}`} target="_blank" rel="noopener noreferrer"><Facebook className="facebook-icon" /></a></li>
                      )}
                      {twitterauthor && (
                      <li><a href={`${twitterauthor}`} target="_blank" rel="noopener noreferrer"><Twitter className="twitter-icon" /></a></li>
                      )}
                      {linkedinauthor && (
                      <li><a href={`${linkedinauthor}`} target="_blank" rel="noopener noreferrer"><Linkedin className="linkedin-icon" /></a></li>
                      )}
                      {instagramauthor && (
                      <li><a href={`${instagramauthor}`} target="_blank" rel="noopener noreferrer"><Instagram className="instagram-icon" /></a></li>
                      )}
                      {emailauthor && (
                      <li><a href={`mailto:${emailauthor}`} target="_blank" rel="noopener noreferrer"><Mail className="email-icon" /></a></li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="authorcard taCenter">
              <Link className="SinglePost--Pagination--Link" to="/service/" hreflang="en">
                  <div className="fotoauthor kostumimg"/>
                  <h4>Need a Website Like This? <br />
                  Click Here</h4>
              </Link>
          </div>
          <div className="authorcard fbp">
            <FacebookProvider appId="363762830961156">
              <Page href="https://www.facebook.com/philodotshop" tabs="timeline" adaptContainerWidth="true" smallHeader="true" />
            </FacebookProvider>
          </div>
          <div className="authorcard taCenter">
              <Link className="SinglePost--Pagination--Link" to="/contact/" hreflang="en">
                  <div className="fotoauthor tulisimg"/>
                  <h4>Submit your writings <br />
                  & Earn the Reward</h4>
              </Link>
          </div>
        </div>
        <div className="fbkomen">
          <FacebookProvider appId="363762830961156">
            <Like href={alamatutama + alamatpost} colorScheme="dark" showFaces layout="button_count" size="large" share />
            <Comments href={alamatutama + alamatpost} />
          </FacebookProvider>
        </div>
        <div className="SinglePost--Pagination">
                {prevPostURL && (
                  <Link
                    className="SinglePost--Pagination--Link prev"
                    to={prevPostURL}
                    hreflang="en"
                  >
                    Previous Post
                  </Link>
                )}
                {nextPostURL && (
                  <Link
                    className="SinglePost--Pagination--Link next"
                    to={nextPostURL}
                    hreflang="en"
                  >
                    Next Post
                  </Link>
                )}
        </div>
      </div>
    </article>
  </main>
)

// Export Default SinglePost for front-end
const SinglePost = ({ data: { post, allPosts, allPenulis } }) => {
  const thisEdge = allPosts.edges.find(edge => edge.node.id === post.id)
  const thisPenulis = allPenulis.edges.find(edge => edge.node.frontmatter.nama === post.frontmatter.author)

  return (
    <Layout
      meta={post.frontmatter.meta || false}
      title={post.frontmatter.title || false}
    >
      <SinglePostTemplate
        {...post}
        {...post.frontmatter}
        body={post.html}
        nextPostURL={_get(thisEdge, 'next.fields.slug')}
        prevPostURL={_get(thisEdge, 'previous.fields.slug')}
        namaauthor={_get(thisPenulis, 'node.frontmatter.nama')}
        bioauthor={_get(thisPenulis, 'node.frontmatter.bio')}
        fotoauthor={_get(thisPenulis, 'node.frontmatter.foto')}
        facebookauthor={_get(thisPenulis, 'node.frontmatter.facebook')}
        twitterauthor={_get(thisPenulis, 'node.frontmatter.twitter')}
        linkedinauthor={_get(thisPenulis, 'node.frontmatter.linkedin')}
        instagramauthor={_get(thisPenulis, 'node.frontmatter.instagram')}
        emailauthor={_get(thisPenulis, 'node.frontmatter.email')}
        alamatpost={_get(thisEdge, 'node.fields.slug')}
        tags={post.frontmatter.tags || false}
      />
    </Layout>
  )
}

export default SinglePost

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SinglePost($id: String!) {
    post: markdownRemark(
      id: { eq: $id }
      ) {
      ...Meta
      html
      id
      fields {
        slug
      }
      frontmatter {
        title
        template
        subtitle
        date(formatString: "MMMM Do, YYYY")
        author
        tags
        categories {
          category
        }
      }
    }

    allPenulis: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "authors" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            nama
            bio
            facebook
            twitter
            linkedin
            instagram
            foto
            email
          }
        }
      }
    }

    allPosts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`